// created for the Education on Demand updates May 2021


import React from 'react'

import PropTypes from 'prop-types'
import BrightcoveVideoComponent from '../brightcove-video/brightcove-video.component'
import TimeLinkComponent from './time-link.component'
import './video-modal.component.scss'
import SiteLink from '../SiteLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trackGa } from '../GoogleTracking'


class VideoModalComponent extends React.Component {

  constructor(props){
    super(props);

    this.videoOverlay = React.createRef();

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.setVideoTime = this.setVideoTime.bind(this);
    this.showVideoOverlay = this.showVideoOverlay.bind(this);
    this.hideVideoOverlay = this.hideVideoOverlay.bind(this);
  }

  handleCloseModal (e, el) {
    var modalComponent = el;
    
    // stop video playback
    var video = modalComponent.querySelector('video');
    if(video){
      video.pause();
      video.currentTime = 0;
    }

    // remove overlay
    this.hideVideoOverlay();

    // hide component
    if(modalComponent.classList.contains("active"))
      modalComponent.classList.remove("active");

    e.stopPropagation();
  }

  setVideoTime (time) {
    // remove the video overlay if it's active 
    this.hideVideoOverlay();

    // set the video time 
    var video = document.querySelector(`video[data-video-id="${this.props.data.videoId}"]`);
    video.currentTime = time;

    // autoplay the video
    video.play();
  }

  showVideoOverlay(){
    if(!this.videoOverlay.current.classList.contains("active")){
      this.videoOverlay.current.classList.add("active");
    }
  }

  hideVideoOverlay(){
    if(this.videoOverlay.current.classList.contains("active")){
      this.videoOverlay.current.classList.remove("active");
    }  
  }

  render () {
    return (
      <div className="video-modal-wrapper" onClick={(e) => this.handleCloseModal(e, e.target) } >
        <div className="video-modal" onClick={(e) => e.stopPropagation()}>
          <div className="modal-close-button" onClick={(e) => this.handleCloseModal(e, e.target.parentNode.parentNode)}>X</div>
          <div className="video-modal-content">
            <div className="video-modal-media-wrapper">
              <div className="video-modal-brightcove-wrapper">
                <BrightcoveVideoComponent
                  player={this.props.data.player}
                  account={this.props.data.account}
                  videoId={this.props.data.videoId}
                  tracking={this.props.data.tracking}
                  trackPercentComplete={this.props.data.trackPercentComplete}
                  whenFinished={this.showVideoOverlay}
                  src={this.props.data.src}
                />
                <div className="video-modal-completed-overlay" data-overlay-id={this.props.data.videoId} ref={this.videoOverlay}>
                  <div className="video-modal-completed-overlay-learn-more">
                    <h3>Thanks for watching!</h3>
                    <p>Learn more about the following topics:</p>
                    
                    {
                      this.props.data.hasOnCompleteOverlay &&
                      this.props.data.onCompleteOverlayLinks.map((link, i) =>
                        <div className="video-modal-completed-overlay-link" key={i}>
                          <a href={link.href} onClick={() => trackGa(link.tracking)}>{link.text}</a>
                        </div>
                      )
                    }
                  </div>
                  <div className="video-modal-completed-overlay-footer">
                    <p>Connect with us to stay informed about narcolepsy</p>
                    <SiteLink 
                      className="join-narcolepsylink-link"
                      to="/stay-connected/join-the-sleep-specialist-directory/"
                      tracking={this.props.data.modalJoinTracking}
                    >
                      Join NarcolepsyLink <FontAwesomeIcon icon="angle-right" />
                    </SiteLink>
                  </div>
                </div>
              </div>
              <div className="interview-modal-title">
                { this.props.data.title }
              </div>
              <div className="interview-modal-description">
                { this.props.data.description }
              </div>
            </div>
            <div className="video-modal-time-links">
              {
                this.props.data.hasTimeMarkers && 
                <div className="video-modal-time-links-content-wrapper">
                  <h3 className="video-modal-time-links-header">Topics Covered:</h3>
                  {
                    this.props.data.timeMarkers.map((link, i) => <TimeLinkComponent data={link} click={this.setVideoTime} key={i} />)
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VideoModalComponent.propTypes = {
  data: PropTypes.object.isRequired
}
export default VideoModalComponent;
