// created for the Education on Demand updates May 2021


import React from 'react'

import PropTypes from 'prop-types'
import './time-link.component.scss'

class TimeLinkComponent extends React.Component {
  constructor(props){
    super(props);
  }

  getFormattedDate(){
    var date = new Date(0,0,0,0,0,this.props.data.time);
    var time = `${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2,'0')}`;

    return `[${time}]`;
  }

  render () {
    return (
      <div className="time-link">
        <a className="time-link-text" onClick={(e) => this.props.click(this.props.data.time)}>{this.props.data.title}</a>&nbsp;&nbsp;
        <span className="time-link-timestamp">{this.getFormattedDate()}</span>
      </div>
    );
  }
}

TimeLinkComponent.propTypes = {
  data: PropTypes.object.isRequired, 
  click: PropTypes.func.isRequired
}
export default TimeLinkComponent;
